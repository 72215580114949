import mock from '../mock'

const data = {
    gebaude: [
        {
            id: 1,
            nutzungsart: 'Privatwohnung',
            gebaude_art: 'Wohnung',
            strasse: 'Musterstraße',
            hausnummer: '1',
            postleitzahl: '12345',
            ort: 'Musterstadt',
            land: 'Musterland',
            bezeichnung: 'Bezeichnung des Hauses',
            notiz: 'Notiz zum Haus "Privatwohnung" und noch weitere Infos.'
        },
        {
            id: 2,
            nutzungsart: 'Privatwohnung',
            gebaude_art: 'Wohnung',
            strasse: 'Musterstraße',
            hausnummer: '1',
            postleitzahl: '12345',
            ort: 'Musterstadt',
            land: 'Musterland',
            bezeichnung: 'Bezeichnung des Hauses',
            notiz: 'Notiz zum Haus "Privatwohnung" und noch weitere Infos.'
        },
        {
            id: 3,
            nutzungsart: 'Privatwohnung',
            gebaude_art: 'Wohnung',
            strasse: 'Musterstraße',
            hausnummer: '1',
            postleitzahl: '12345',
            ort: 'Musterstadt',
            land: 'Musterland',
            bezeichnung: 'Bezeichnung des Hauses',
            notiz: 'Notiz zum Haus "Privatwohnung" und noch weitere Infos.'
        },
        {
            id: 4,
            nutzungsart: 'Privatwohnung',
            gebaude_art: 'Wohnung',
            strasse: 'Musterstraße',
            hausnummer: '1',
            postleitzahl: '12345',
            ort: 'Musterstadt',
            land: 'Musterland',
            bezeichnung: 'Bezeichnung des Hauses',
            notiz: 'Notiz zum Haus "Privatwohnung" und noch weitere Infos.'
        },
        {
            id: 5,
            nutzungsart: 'Privatwohnung',
            gebaude_art: 'Wohnung',
            strasse: 'Musterstraße',
            hausnummer: '1',
            postleitzahl: '12345',
            ort: 'Musterstadt',
            land: 'Musterland',
            bezeichnung: 'Bezeichnung des Hauses',
            notiz: 'Notiz zum Haus "Privatwohnung" und noch weitere Infos.'
        }
    ]
}

mock.onGet('/gebaude').reply(() => [200, data.gebaude])
mock.onGet('/gebaude/edit').reply((config) => {
    // Get event id from URL

    let userId = config.id

    //  Convert Id to number
    userId = Number(userId)

    const chat = data.chats.find((c) => c.id === userId)
    if (chat) chat.unseenMsgs = 0
    const contact = data.contacts.find((c) => c.id === userId)
    if (contact.chat) contact.chat.unseenMsgs = 0
    return [200, { chat, contact }]
})
